<template>
    <section class="Showreel">
        <div class="top-bar">
            <h3>{{title != null && title != '' ? title : 'Showreel'}}</h3>
        </div>

        <div class="content">
            <button class="btn-arrow left" @click="goPrev()"></button>
            <button class="btn-arrow right" @click="goNext()"></button>
            <div class="imgs-box">
                <img :src="require(`@/assets/imgs/${imgShow.find}`)" :alt="imgShow.alt" :style="imgShow.imgType == 'contain' ? 'object-fit: contain; padding: 10px' : 'object-fit: cover;'">
                <p v-if="imgShow.text != '' && imgShow.text != null">{{imgShow.text}}</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Showreel',
    props:{
        title: String,
        imgs: Array
    },
    data(){
        return{
            index: 0,
            imgShow: this.imgs[0],
        }
    },
    methods: {
        goPrev(){
            if(this.index <= 0){
                this.index = this.imgs.length - 1
            }else{
                this.index--;
            }
            this.changeImg();
        },
        goNext(){
            if(this.index >= this.imgs.length - 1){
                this.index = 0
            }else{
                this.index++;
            }
            this.changeImg();
        },
        changeImg(){
            this.imgShow = this.imgs[this.index]
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.Showreel{
    margin-top: $work-comp-mt;

    background-color: $c-window-bg;
    border: $main-borders;
    box-shadow: $shadow;

    .top-bar{
        padding: 15px 30px;
        border-bottom: $main-borders;
        h3{
            @include f-secondary-top-title;
        }
    }

    .content{
        position: relative;
        @include flex-center;

        .btn-arrow.left{
            position: absolute;
            top: 50%;
            left: 30px;
            transform: translateY(-50%)
        }
        .btn-arrow.right{
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%)
        }

        .imgs-box{
            @include img-fit-cover;
            height: 600px;
            width: 940px;
            margin: 30px;
            border: $main-borders;
            position: relative;
            background-color: white;

            p{
                @include sans-medium;
                position: absolute;
                left: 50%;
                bottom: -20px;
                transform: translateX(-50%);
                max-width: 100%;
                padding: 2px 10px;
                border: $main-borders;
                background-color: white;
            }
        }
    }
}

@media screen and (max-width: 980px){
    .btn-arrow.left{
        left: 10px !important;
    }
    .btn-arrow.right{
        right: 10px !important;
    }
}
@media screen and (max-width: 850px){
    .imgs-box{
        height: 500px !important;
    }
}
@media screen and (max-width: 680px){
    .imgs-box{
        height: 400px !important;
        p{
            display: none !important;
        }
    }
}
@media screen and (max-width: 500px){
    .imgs-box{
        height: 250px !important;
    }
    .btn-arrow.left, .btn-arrow.right{
        height: 100px;
        width: 40px;
        box-shadow: none;
        background: rgba($color: $c-02, $alpha: .7);
    }
    .btn-arrow.left{
        left: -5px !important;
    }
    .btn-arrow.right{
        right: -5px !important;
    }
}

</style>