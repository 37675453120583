<template>
    <section id="ProjTypography">
        

        <div class="container">
        
        <!-- PAGE TITLE -->
            <page-title 
                title="Art of Typography" 
                subtitle="Questo progetto scolastico mirava a simulare la realizzazione di un evento. È stata la mia tesi per l'esame di maturità 2017. Ho progettato la parte grafica e comunicativa di una mostra sulla tipografia ideata da me e ipoteticamente ospitata al Philadelphia Museum of Art." 
            />
        <!-- --------------------- -->

        <!-- PHOTO SHOWREEL -->
            <Showreel
                title=""
                :imgs="[
                    //Find is a path and start automatically from imgs folder..
                    //if want find image in a folder inside imgs folder u need to specify which folder before img name
                    //(folder/name.jpg)
                    {
                        find: 'proj-art-typography/show1.png',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-art-typography/show2.png',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-art-typography/show3.png',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                    {
                        find: 'proj-art-typography/show4.png',
                        imgType: 'cover',
                        alt: '', // <-- image alt text
                        text: ''
                    },
                ]"
            />
        <!-- --------------------- -->

        </div>
    </section>
</template>

<script>
import PageTitle from '../../components/small/PageTitle.vue'
import Showreel from '../../components/works-project/Showreel.vue'

export default {
    name: 'ProjTypography',
    components: {
        PageTitle,
        Showreel
    },
    metaInfo: {
        title: 'Art of Typography',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: 'Questo progetto scolastico mirava a simulare la realizzazione di un evento. È stata la mia tesi per l\'esame di maturità 2017. Ho progettato la parte grafica e comunicativa di una mostra sulla tipografia ideata da me e ipoteticamente ospitata al Philadelphia Museum of Art.',
        }],
    },
}
</script>


<style scoped lang="scss">
@import '@/scss/_variables.scss';

</style>