var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"ProjTypography"}},[_c('div',{staticClass:"container"},[_c('page-title',{attrs:{"title":"Art of Typography","subtitle":"Questo progetto scolastico mirava a simulare la realizzazione di un evento. È stata la mia tesi per l'esame di maturità 2017. Ho progettato la parte grafica e comunicativa di una mostra sulla tipografia ideata da me e ipoteticamente ospitata al Philadelphia Museum of Art."}}),_c('Showreel',{attrs:{"title":"","imgs":[
                //Find is a path and start automatically from imgs folder..
                //if want find image in a folder inside imgs folder u need to specify which folder before img name
                //(folder/name.jpg)
                {
                    find: 'proj-art-typography/show1.png',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-art-typography/show2.png',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-art-typography/show3.png',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                },
                {
                    find: 'proj-art-typography/show4.png',
                    imgType: 'cover',
                    alt: '', // <-- image alt text
                    text: ''
                } ]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }